





































import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Button from '@/components/Button/Button.vue';
import CloseButton from '@/components/DetailedViewPopup/ActionButton.vue';

export default Vue.extend({
  name: 'FormBase',
  components: {
    LoadingIndicator,
    Button,
    CloseButton,
  },
  props: {
    title: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    loading: Boolean,
    isAdditionDialog: { type: Boolean, default: false },
    hideOverflow: { type: Boolean, default: false },
  },
  data() {
    return {
      opened: false,
      closeIcon: '/images/detailed-view-close.svg',
    };
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.$emit('closed');
    },
  },
});
